import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import userSlice from "../reducerSlices/userSlice";
import paymentSlice from "../reducerSlices/paymentSlice";
import attendanceSlice from "../reducerSlices/attendanceSlice";
import generalSlice from "../reducerSlices/generalSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  paymentUser: paymentSlice,
  classDataForAttendance: attendanceSlice,
  general: generalSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
});

export const persistor = persistStore(store);
