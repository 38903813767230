import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openSidebar: true,
  isMobileDevice: false,
  dashboardLink: "",
};

const generalSlice = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.openSidebar =
        action.payload != undefined ? action.payload : !state.openSidebar;
    },
    setMobileDevice: (state, action) => {
      state.isMobileDevice = action.payload;
    },
    setDashboardLink: (state, action) => {
      state.dashboardLink = action.payload;
    },
  },
});

export const { toggleSidebar, setMobileDevice, setDashboardLink } =
  generalSlice.actions;
export default generalSlice.reducer;
