import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedClassAndSectionInformation: {},
};

const attendanceSlice = createSlice({
    name:"attendanceData",
    initialState,
    reducers: {
        setSelectedClassAndSectionInformation(state, action) {
            state.selectedClassAndSectionInformation = action.payload;
        },
    },
});

export const { setSelectedClassAndSectionInformation } = attendanceSlice.actions;
export default attendanceSlice.reducer;