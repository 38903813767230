import { Layout } from "antd";
import React, { Suspense } from "react";

import NavBar from "./navBar";
import Sidebar from "./sidebar";
import SkeletonLoader from "./Skeleton";
import { ErrorBoundary } from "./ErrorBoundary";

export default function LazyRoute(Component, props = {}) {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      {props.noMainLayout ? (
        <Suspense fallback={<SkeletonLoader />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Layout>
          <Sidebar />
          <Layout>
            <NavBar />
            <Layout.Content>
              <Suspense fallback={<SkeletonLoader />}>
                <Component {...props} />
              </Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      )}
    </ErrorBoundary>
  );
}
