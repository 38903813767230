import {
  MdOutlineSpaceDashboard,
  MdChatBubbleOutline,
  MdCoPresent,
  MdPayments,
  MdOutlineManageHistory,
  MdNotificationAdd
} from "react-icons/md";
import { IoCalendarOutline, IoBusOutline } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";
import { TbReportSearch } from "react-icons/tb";
import { IoIosGitPullRequest } from "react-icons/io";
import { LuFileSpreadsheet } from "react-icons/lu";
import { SlPeople } from "react-icons/sl";
import { IoMdPersonAdd } from "react-icons/io";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdReceiptLong, MdForum } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import { TbMoneybag } from "react-icons/tb";





const iconMapping = {
  MdOutlineSpaceDashboard,
  IoCalendarOutline,
  IoBusOutline,
  PiStudent,
  MdCoPresent,
  TbReportSearch,
  IoIosGitPullRequest,
  LuFileSpreadsheet,
  SlPeople,
  IoMdPersonAdd,
  MdChatBubbleOutline,
  MdPayments,
  FaFileInvoiceDollar,
  MdOutlineManageHistory,
  MdReceiptLong,
  FaTasks,
  MdForum,
  MdNotificationAdd,
  IoMdNotifications,
  MdOutlineMessage,
  TbMoneybag
};

export default iconMapping;
