import axios from "axios";
import toast from "react-hot-toast";

// export const API_URL = "http://localhost:8000";
export const API_URL = "https://api.enlightbook.com";

export const axiosInstance = axios.create({
  baseURL: API_URL + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const err =
      error?.response?.data?.message ||
      error?.response?.data?.deatils ||
      error?.response?.data?.error ||
      "Something went wrong. Please try again.";
    toast.error(err);

    return Promise.reject(error);
  }
);
