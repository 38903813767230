import { useEffect, useState } from "react";

export default function useResize() {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  // Function to update visibility based on screen size
  const updateSidebarVisibility = () => {
    const width = window.innerWidth;
    if (width < 576) {
      setIsMobileDevice(true);
      setIsSidebarVisible(false);
    } else if (width < 768) {
      setIsMobileDevice(false);
      setIsSidebarVisible(false);
    } else {
      setIsSidebarVisible(true);
    }
  };

  // Listen resize
  useEffect(() => {
    updateSidebarVisibility(); // Initial check

    window.addEventListener("resize", updateSidebarVisibility);

    return () => window.removeEventListener("resize", updateSidebarVisibility); // Cleanup
  }, []);

  return { isSidebarVisible, isMobileDevice };
}
